import { default as emailsW1UqugskrBMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/emails.vue?macro=true";
import { default as integrationsdy1MnojWmCMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/integrations.vue?macro=true";
import { default as overviewa5EzbJwbBqMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/overview.vue?macro=true";
import { default as indexcgTZWe11GSMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/structures/[id]/index.vue?macro=true";
import { default as subjectsxBx6woPNhRMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/structures/[id]/subjects.vue?macro=true";
import { default as indexPhuZw7Gbs1Meta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/structures/index.vue?macro=true";
import { default as indexUcidZNO3xSMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/users/[id]/index.vue?macro=true";
import { default as addPy0JaDviSbMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/users/add.vue?macro=true";
import { default as indexUGQpEOivMaMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/users/index.vue?macro=true";
import { default as adminWZH7T8ytCeMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin.vue?macro=true";
import { default as account43lQO0dX7DMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/account.vue?macro=true";
import { default as entouragehEz35NHcETMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/beneficiaries/[id]/edit/entourage.vue?macro=true";
import { default as external_45organisationsZT8e8ftHrYMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/beneficiaries/[id]/edit/external-organisations.vue?macro=true";
import { default as healthcqa6stFGciMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/beneficiaries/[id]/edit/health.vue?macro=true";
import { default as infodPo1uSyYMsMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/beneficiaries/[id]/edit/info.vue?macro=true";
import { default as occupationMWQiAG4JVRMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/beneficiaries/[id]/edit/occupation.vue?macro=true";
import { default as tax_45householdpU51YnCHCPMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/beneficiaries/[id]/edit/tax-household.vue?macro=true";
import { default as printkQPY1HcasbMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/beneficiaries/[id]/print.vue?macro=true";
import { default as indexa6R6Zleg97Meta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/beneficiaries/index.vue?macro=true";
import { default as edit7ZBGInCzmeMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/budget/edit.vue?macro=true";
import { default as editM7y4y7b9gLMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/expenses/[eid]/edit.vue?macro=true";
import { default as editdouwFtyaflMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/followups/[fid]/edit.vue?macro=true";
import { default as addZaPIHVC4hVMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/followups/add.vue?macro=true";
import { default as editY4q9WQgrqoMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue?macro=true";
import { default as addiO19b7xk1fMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/help-requests/[subject]/add.vue?macro=true";
import { default as editjVoxebtpDQMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue?macro=true";
import { default as addaASwqvwJviMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/help-requests/housing/add.vue?macro=true";
import { default as editdasauHUW30Meta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/income/[iid]/edit.vue?macro=true";
import { default as indexcba0uIoq27Meta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/index.vue?macro=true";
import { default as addkUpetBXhE5Meta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/add.vue?macro=true";
import { default as historyesyuAXD7tYMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/history.vue?macro=true";
import { default as printUQD7xeuUmnMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/historyprint/[id]/print.vue?macro=true";
import { default as overviewRyy9NiFaaAMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/overview.vue?macro=true";
import { default as partnersVOIJwygeYlMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/partners.vue?macro=true";
import { default as statsAzymT80v44Meta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/stats.vue?macro=true";
import { default as indexjoS4QjmKDaMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/structure/index.vue?macro=true";
import { default as subjectsZ6V8ADYTGgMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/structure/subjects.vue?macro=true";
import { default as usersPe13QkXoyyMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/users.vue?macro=true";
import { default as appD41KgwGIBUMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app.vue?macro=true";
import { default as adminChAwz0hetfMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/auth/admin.vue?macro=true";
import { default as login89WpnFNlv3Meta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/auth/login.vue?macro=true";
import { default as logoutqXe60s7FHMMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/auth/logout.vue?macro=true";
import { default as proconnectnMmIL1FD86Meta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/auth/proconnect.vue?macro=true";
import { default as validate_45tokenEF9lwjKKV7Meta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/auth/validate-token.vue?macro=true";
import { default as managerz7myPy7L2kMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/cgu/manager.vue?macro=true";
import { default as user30vrnRO9vCMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/cgu/user.vue?macro=true";
import { default as cguaUsspJgmB1Meta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/cgu.vue?macro=true";
import { default as declaration_45accessibilite4vijCrzfxqMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/declaration-accessibilite.vue?macro=true";
import { default as disabled_45accountxnSuYmDvuGMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/error/disabled-account.vue?macro=true";
import { default as unknown_45userVNIt1znbFTMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/error/unknown-user.vue?macro=true";
import { default as indexbXuAxEzTaSMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/index.vue?macro=true";
import { default as mentions_45legalesyhDpMbL82nMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/mentions-legales.vue?macro=true";
import { default as politique_45confidentialitebpEOJL2jdLMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/politique-confidentialite.vue?macro=true";
import { default as registerjzIlrWTpZaMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/register.vue?macro=true";
import { default as statistiques1dkbi040xBMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/statistiques.vue?macro=true";
import { default as storiesnkHQ6RDhoIMeta } from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/stories.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: adminWZH7T8ytCeMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-emails",
    path: "emails",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/emails.vue").then(m => m.default || m)
  },
  {
    name: "admin-integrations",
    path: "integrations",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/integrations.vue").then(m => m.default || m)
  },
  {
    name: overviewa5EzbJwbBqMeta?.name ?? "admin-overview",
    path: "overview",
    meta: overviewa5EzbJwbBqMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/overview.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures-id",
    path: "structures/:id()",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/structures/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures-id-subjects",
    path: "structures/:id()/subjects",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/structures/[id]/subjects.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures",
    path: "structures",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/structures/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-add",
    path: "users/add",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/users/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/admin/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app",
    path: "/app",
    meta: appD41KgwGIBUMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app.vue").then(m => m.default || m),
    children: [
  {
    name: "app-account",
    path: "account",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/account.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-entourage",
    path: "beneficiaries/:id()/edit/entourage",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/beneficiaries/[id]/edit/entourage.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-external-organisations",
    path: "beneficiaries/:id()/edit/external-organisations",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/beneficiaries/[id]/edit/external-organisations.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-health",
    path: "beneficiaries/:id()/edit/health",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/beneficiaries/[id]/edit/health.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-info",
    path: "beneficiaries/:id()/edit/info",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/beneficiaries/[id]/edit/info.vue").then(m => m.default || m)
  },
  {
    name: occupationMWQiAG4JVRMeta?.name ?? "app-beneficiaries-id-edit-occupation",
    path: "beneficiaries/:id()/edit/occupation",
    meta: occupationMWQiAG4JVRMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/beneficiaries/[id]/edit/occupation.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-tax-household",
    path: "beneficiaries/:id()/edit/tax-household",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/beneficiaries/[id]/edit/tax-household.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-print",
    path: "beneficiaries/:id()/print",
    meta: printkQPY1HcasbMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/beneficiaries/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: indexa6R6Zleg97Meta?.name ?? "app-beneficiaries",
    path: "beneficiaries",
    meta: indexa6R6Zleg97Meta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/beneficiaries/index.vue").then(m => m.default || m)
  },
  {
    name: edit7ZBGInCzmeMeta?.name ?? "app-files-id-budget-edit",
    path: "files/:id()/budget/edit",
    meta: edit7ZBGInCzmeMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/budget/edit.vue").then(m => m.default || m)
  },
  {
    name: editM7y4y7b9gLMeta?.name ?? "app-files-id-expenses-eid-edit",
    path: "files/:id()/expenses/:eid()/edit",
    meta: editM7y4y7b9gLMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/expenses/[eid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-followups-fid-edit",
    path: "files/:id()/followups/:fid()/edit",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/followups/[fid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-followups-add",
    path: "files/:id()/followups/add",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/followups/add.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-subject-hrid-edit",
    path: "files/:id()/help-requests/:subject()/:hrid()/edit",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-subject-add",
    path: "files/:id()/help-requests/:subject()/add",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/help-requests/[subject]/add.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-housing-hrid-edit",
    path: "files/:id()/help-requests/housing/:hrid()/edit",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-housing-add",
    path: "files/:id()/help-requests/housing/add",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/help-requests/housing/add.vue").then(m => m.default || m)
  },
  {
    name: editdasauHUW30Meta?.name ?? "app-files-id-income-iid-edit",
    path: "files/:id()/income/:iid()/edit",
    meta: editdasauHUW30Meta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/income/[iid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id",
    path: "files/:id()",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "app-files-add",
    path: "files/add",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/files/add.vue").then(m => m.default || m)
  },
  {
    name: "app-history",
    path: "history",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/history.vue").then(m => m.default || m)
  },
  {
    name: "app-historyprint-id-print",
    path: "historyprint/:id()/print",
    meta: printUQD7xeuUmnMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/historyprint/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: overviewRyy9NiFaaAMeta?.name ?? "app-overview",
    path: "overview",
    meta: overviewRyy9NiFaaAMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/overview.vue").then(m => m.default || m)
  },
  {
    name: "app-partners",
    path: "partners",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/partners.vue").then(m => m.default || m)
  },
  {
    name: "app-stats",
    path: "stats",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/stats.vue").then(m => m.default || m)
  },
  {
    name: "app-structure",
    path: "structure",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/structure/index.vue").then(m => m.default || m)
  },
  {
    name: "app-structure-subjects",
    path: "structure/subjects",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/structure/subjects.vue").then(m => m.default || m)
  },
  {
    name: "app-users",
    path: "users",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/app/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-admin",
    path: "/auth/admin",
    meta: adminChAwz0hetfMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/auth/admin.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: login89WpnFNlv3Meta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutqXe60s7FHMMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-proconnect",
    path: "/auth/proconnect",
    meta: proconnectnMmIL1FD86Meta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/auth/proconnect.vue").then(m => m.default || m)
  },
  {
    name: "auth-validate-token",
    path: "/auth/validate-token",
    meta: validate_45tokenEF9lwjKKV7Meta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/auth/validate-token.vue").then(m => m.default || m)
  },
  {
    name: "cgu",
    path: "/cgu",
    meta: cguaUsspJgmB1Meta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/cgu.vue").then(m => m.default || m),
    children: [
  {
    name: "cgu-manager",
    path: "manager",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/cgu/manager.vue").then(m => m.default || m)
  },
  {
    name: "cgu-user",
    path: "user",
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/cgu/user.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "declaration-accessibilite",
    path: "/declaration-accessibilite",
    meta: declaration_45accessibilite4vijCrzfxqMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/declaration-accessibilite.vue").then(m => m.default || m)
  },
  {
    name: "error-disabled-account",
    path: "/error/disabled-account",
    meta: disabled_45accountxnSuYmDvuGMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/error/disabled-account.vue").then(m => m.default || m)
  },
  {
    name: "error-unknown-user",
    path: "/error/unknown-user",
    meta: unknown_45userVNIt1znbFTMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/error/unknown-user.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexbXuAxEzTaSMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    meta: mentions_45legalesyhDpMbL82nMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    meta: politique_45confidentialitebpEOJL2jdLMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/politique-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerjzIlrWTpZaMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "statistiques",
    path: "/statistiques",
    meta: statistiques1dkbi040xBMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/statistiques.vue").then(m => m.default || m)
  },
  {
    name: "stories",
    path: "/stories",
    meta: storiesnkHQ6RDhoIMeta || {},
    component: () => import("/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/pages/stories.vue").then(m => m.default || m)
  }
]