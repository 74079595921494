import revive_payload_client_lC23sBIpQt from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.1_eslint@8.57.0_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7VSv0RSasw from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.1_eslint@8.57.0_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qbQfLWSuAz from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.1_eslint@8.57.0_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_fmMMFY45Oc from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.1_eslint@8.57.0_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vIy58i8hZU from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.1_eslint@8.57.0_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_xACZUxdofC from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.1_eslint@8.57.0_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_4td3fcaMKv from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.1_eslint@8.57.0_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/.nuxt/components.plugin.mjs";
import prefetch_client_v4xlYydUB3 from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.1_eslint@8.57.0_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import auth_vT9JWWT9pN from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/plugins/auth.ts";
import autofocus_1LURlzQ7IF from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/plugins/autofocus.ts";
import chart_client_qQ92gke75l from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/plugins/chart.client.ts";
import dayjs_pGiXRjcsJO from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/plugins/dayjs.ts";
import dsfr_client_wnEKqTCG96 from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/plugins/dsfr.client.js";
import global_event_emitter_AhsutoD9go from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/plugins/global-event-emitter.ts";
import matomo_client_Ed2EqINkhd from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/plugins/matomo.client.ts";
import modal_UEFnUXzgTX from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/plugins/modal.ts";
import refresh_app_client_BPM4AstEHA from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/plugins/refresh-app.client.ts";
import sentry_client_shVUlIjFLk from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/plugins/sentry.client.ts";
import trpcClient_M8UiGTyzsx from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/plugins/trpcClient.ts";
import vueDsfr_0DkzeX1IAt from "/build/2e275556-0a79-4bdc-8ffe-08ccbf539d9d/plugins/vueDsfr.ts";
export default [
  revive_payload_client_lC23sBIpQt,
  unhead_7VSv0RSasw,
  router_qbQfLWSuAz,
  payload_client_fmMMFY45Oc,
  navigation_repaint_client_vIy58i8hZU,
  check_outdated_build_client_xACZUxdofC,
  chunk_reload_client_4td3fcaMKv,
  components_plugin_KR1HBZs4kY,
  prefetch_client_v4xlYydUB3,
  auth_vT9JWWT9pN,
  autofocus_1LURlzQ7IF,
  chart_client_qQ92gke75l,
  dayjs_pGiXRjcsJO,
  dsfr_client_wnEKqTCG96,
  global_event_emitter_AhsutoD9go,
  matomo_client_Ed2EqINkhd,
  modal_UEFnUXzgTX,
  refresh_app_client_BPM4AstEHA,
  sentry_client_shVUlIjFLk,
  trpcClient_M8UiGTyzsx,
  vueDsfr_0DkzeX1IAt
]